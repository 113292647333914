import React from "react";
import "../assets/Hero.css";
import Typed from "react-typed";
import laptopEdyug from "../assets/images/laptop-edyug.png";
import phoneEdyug from "../assets/images/phone-edyug.png";
import { motion } from "framer-motion";
// import aboutImg from "../assets/images/about-feature-img.svg";

// import { FaDatabase } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { IoBarChart } from "react-icons/io5";
import { FaCompressArrowsAlt } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
// import { BiSolidLayer } from "react-icons/bi";
import { FaLayerGroup } from "react-icons/fa";
import { Link } from "react-router-dom";
import BookDemo from "./BookDemo";

const Hero = () => {
  return (
    <section className="hero-bg">
      <div className="text-white">
        <div className="hero-flex">
          <div className="hero-left max-w-[600px]">
            <motion.div
              className="flex justify-start items-center mb-6"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Typed
                className="md:text-4xl sm:text-3xl text-xl text-[#17b6b6] font-bold "
                strings={[
                  "Website Development",
                  "School/College ERP Software",
                  "Digital Marketing",
                ]}
                typeSpeed={50}
                backSpeed={50}
                loop
              />
            </motion.div>

            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="md:text-4xl text-[#F8B849] font-bold sm:text-4xl text-[27px] pb-4 md:pb-6"
            >
              {/* Beautiful Moment of Album */}
              Leading Education ERP Software Provider In India
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="md:text-xl text-[16px] text-left pb-4 md:pb-6 text-white"
            >
              Our cloud ERP software comes with customizable features along with
              web-portal and mobile app solutions.
            </motion.p>
            <motion.ul
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="why-points pb-6 "
            >
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <FaLayerGroup className="text-[#17B6B6]" /> School/College ERP
                Software
              </li>

              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <MdSecurity className="text-[#17B6B6]" /> High Quality Security
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <CgWebsite className="text-[#17B6B6]" /> Website Development
              </li>

              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <BiSupport className="text-[#17B6B6]" /> 24*7 Support Team
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <IoBarChart className="text-[#17B6B6]" /> Digital Marketing
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <FaCompressArrowsAlt className="text-[#17B6B6]" /> Easy
                Solutions
              </li>
            </motion.ul>
            {/* Hero Buttons */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
              className="flex gap-4 mt-2 sm:mt-6"
            >
              <Link to="/about" className="hero-btn">
                Know More
              </Link>
              <BookDemo />
            </motion.div>
          </div>
          <div className="hero-right">
            <motion.img
              className="laptop"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1.1 }}
              transition={{ duration: 0.5 }}
              src={laptopEdyug}
              alt="laptopImg"
              style={{ zIndex: 1 }}
            />
            <motion.img
              className="mobile"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1.1, left: 470 }}
              transition={{ duration: 0.6, delay: 0.5 }}
              // whileTap={{ opacity: 1, scale: 2 }}
              // drag
              src={phoneEdyug}
              alt="mobileImg"
              style={{ zIndex: 1 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
