// Popup.jsx
import React, { useState, useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";
import "../assets/Popup.css";
import limitedOffer from "../assets/images/limited-offer.gif";

const Popup = () => {
  // State to manage the visibility of the popup
  const [isOpen, setIsOpen] = useState(false);

  // Ref to detect clicks outside of the popup
  const popupRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      // Open popup when component mounts
      setIsOpen(true);
    }, 1000);

    // Add event listener to handle clicks outside the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-start sm:items-center  justify-center bg-black bg-opacity-70 z-50 ">
          <div
            ref={popupRef}
            className="popup-bg-img  h-[300px] sm:p-8 rounded-lg shadow-lg relative sm:w-96 w-[80%] mx-[10px] sm:mt-[0px]  mt-[180px]"
          >
            <button
              className="absolute top-2 right-2 text-[#ED1A45] hover:text-[#fdd06f]"
              onClick={() => setIsOpen(false)}
            >
              <IoClose size={25} />
            </button>
            {/* <div>
              <img
                src={limitedOffer}
                className="absolute top-10 right-5"
                alt=""
              />
            </div> */}
            {/* <h2 className="text-2xl md:mb-[30px] md:text-[30px] font-bold mb-4 text-center text-[#17b6b6]">
              Special Offer!
            </h2>
            <p className="text-gray-700 text-center font-[600] text-[16px]  md:text-[21px]">
              GET YOUR WEBSITE FOR YOUR BUSINESS/ORGANIZATION TODAY.
            </p>
            <p className="text-gray-700 text-center font-[600] text-[16px]  md:text-[21px]">
              With ZERO development charges, Pay only for hosting and domain.
            </p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
