import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ServicesSection from "../components/ServicesSection";
// import ServicesBox from "../components/ServicesBox";
// import TestimonialSection from "../components/TestimonialSection";
import Breadcrumb from "../components/Breadcrumb";
import Modules from "../components/Modules";

const Services = () => {
  return (
    <div>
      <Navbar />
      <Breadcrumb title="Services">
        <p>
          Our cloud ERP software comes with customizable features along with
          web-portal and mobile app solutions.
        </p>
      </Breadcrumb>
      <ServicesSection />
      <Modules />
      {/* <ServicesBox /> */}
      {/* <TestimonialSection /> */}
      <Footer />
    </div>
  );
};

export default Services;
