import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import schoolImg from "../assets/images/schoolErpImg.jpg";
import "../assets/SchoolErp.css";
import { FaChevronRight } from "react-icons/fa";
import Navbar from "../components/Navbar";
import Modules from "../components/Modules";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";

const SchoolErp = () => {
  return (
    <>
      <Navbar />
      <Breadcrumb title="School ERP">
        <p>
          School ERP makes school task effortless and offers the best academic
          experience to their students.
        </p>
      </Breadcrumb>
      <div className="school-section">
        <div className="school-container">
          <div className="school-about mb-[80px]">
            <motion.div
              className="school-left"
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <h3 className="text-[18px] sm:text-2xl text-[#FF3951] font-bold pl-1">
                School ERP
              </h3>
              <h2 className="text-[21px] sm:text-3xl md:text-4xl font-bold text-gray-600">
                Powerful Solutions for multiple modules and platform
              </h2>

              <p>
                School ERP makes school task effortless and offers the best
                academic experience to their students, however many schools
                still rely on manual and outdated methods of managing
                administrative activities like admission, attendance, exam, fees
                etc. Gone are the days of pen and paper. Today’s technology and
                modern management tools are much needed for school to become
                efficient and smarter.
              </p>
              <p>
                Our school management platform keeps parents and schools in
                connective loop all the time which enables parents to monitor
                everything about their kid’s academics.
              </p>

              {/* <Link to="/contact" className="school-btn">
              Contact Us
            </Link> */}
            </motion.div>
            <motion.div
              className="school-right"
              variants={fadeIn("left", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <img
                style={{
                  width: "600px",
                  boxShadow: "1px 2px 15px rgba(5, 131, 148, 0.2)",
                  borderRadius: "5px",
                }}
                src={schoolImg}
                alt=""
              />
            </motion.div>
          </div>
          {/* School Erp Platforms */}
          <div className="school-erp-platforms">
            {/* School Erp Platforms - 1 */}
            <motion.div
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="school-erp-platform"
            >
              <h5 className="platform-heading">Edyug ERP Platform For Admin</h5>
              <div className="platform-points">
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>

                  <p>
                    Our user-friendly dashboard provides an insight to multiple
                    process like student information, examination, design
                    academics, fee reports, notice board, reports.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    It is easy now for admin to deal with admission, promotion
                    etc.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    No more hassle in preparing exam timetables or report cards,
                    our platform make it very swift for admin.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Automated reports on fee can help admin to get useful
                    insights.
                  </p>
                </div>
              </div>
            </motion.div>
            {/* School Erp Platforms - 2 */}
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="school-erp-platform"
            >
              <h5 className="platform-heading">
                Edyug ERP Platform For Teachers
              </h5>

              <div className="platform-points">
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>

                  <p>
                    The dashboard of teachers app displays their information and
                    other important day to day notifications and reports.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Teachers can now add homework/attendance and generate
                    attendance/report cards with their convenient app.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Better coordination between teachers and admin on
                    correction/updation of the marks and report cards.
                  </p>
                </div>
              </div>
            </motion.div>
            {/* School Erp Platforms - 3 */}
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="school-erp-platform"
            >
              <h5 className="platform-heading">
                Edyug ERP Platform For Parents
              </h5>
              <div className="platform-points">
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>

                  <p>
                    We provide a white labeled app for parents, which can keep
                    them connect to college online.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    The well designed dashboard of parent’s app displays their
                    kids information.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Our attendance module helps the parents to fetch the
                    attendance summary of their children.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    The fee module of our app helps the parents to plan on their
                    kids fees, this system also generate reports/fee receipt for
                    parents.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Timetable—An excellent module that keeps parents updated on
                    their kids timetable and studies.
                  </p>
                </div>
                <div className="platform-point">
                  <p>
                    <FaChevronRight className="arrow-point" />
                  </p>
                  <p>
                    Parents can keep them self-updated with upcoming all events,
                    activities going to happen in the college.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <Modules />
      <Footer />
    </>
  );
};

export default SchoolErp;
