import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { Link } from "react-router-dom";
import whyImg from "../assets/images/it-solution.svg";
import "../assets/WhyChoose.css";
// import { FaDatabase } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdSecurity } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { IoBarChart } from "react-icons/io5";
import { FaCompressArrowsAlt } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa";
import BookDemo from "./BookDemo";

const WhyChoose = () => {
  return (
    <>
      <div className="why-section">
        <div className="why-container">
          <div className="why-left">
            <motion.h3
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[18px] sm:text-2xl text-[#FF3951] font-bold pl-1"
            >
              Why Choose Us
            </motion.h3>
            <motion.h2
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[21px] sm:text-4xl md:text-5xl font-bold text-gray-600"
            >
              You Get IT & Business Solution
            </motion.h2>
            {/* <span className="text-[18px] text-blue-600 font-bold ">
              Education + Automation + Technology = Edyug
            </span> */}
            <motion.p
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              Meet Our Automation Experts in Education Domain. Smartly
              Integrated, Fully Managed IT solution as a Service Provider.
            </motion.p>

            <motion.p
              variants={fadeIn("right", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              User Friendly Web and Mobile Based ERP Software Customer Centric
              Products, 24*7 Customer Support.
            </motion.p>
            <motion.ul
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="why-points pb-6 "
            >
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <FaLayerGroup className="text-[#17B6B6]" /> School/College ERP
                Software
              </li>

              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <MdSecurity className="text-[#17B6B6]" /> High Quality Security
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <CgWebsite className="text-[#17B6B6]" /> Website Development
              </li>

              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <BiSupport className="text-[#17B6B6]" /> 24*7 Support Team
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <IoBarChart className="text-[#17B6B6]" /> Digital Marketing
              </li>
              <li className="flex items-center gap-2 sm:font-semibold text-[12px] sm:text-[16px] ">
                <FaCompressArrowsAlt className="text-[#17B6B6]" /> Easy
                Solutions
              </li>
            </motion.ul>
            <motion.div
              variants={fadeIn("right", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              {/* <Link to="/contact" className="why-btn">
                Contact Us
              </Link> */}
              <div className="flex gap-4 my-2">
                <Link to="/contact" className="why-btn">
                  Contact Us
                </Link>
                <BookDemo />
                {/* <Link to="/contact" className="book-btn">
                  Book Demo
                </Link> */}
              </div>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="why-right"
          >
            <img src={whyImg} alt="" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default WhyChoose;
