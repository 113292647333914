import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import "../assets/Blogs.css";
import Breadcrumb from "../components/Breadcrumb";

const Blogs = () => {
  const { slug } = useParams(); // Use slug instead of id

  const blogs = [
    {
      id: 1,
      title: "School/College ERP Software",
      post: 79,
      desc: "Education ERP Software is a web-based integrated software for educational institutions: schools and colleges. Facilitates automation of all key processes of an institute and forms the backbone of a comprehensive Management Information System (MIS).",
      coverImg: "https://edyug.com/img/erps.jpg",
      slug: "school-college-erp-software",
    },
    {
      id: 2,
      title: "Website Development",
      post: 61,
      desc: "A website serves as the face of your Institute in your absence, it creates the first impression on Parents, Students and others. When it comes to digital marketing, creating a good website is the first step for any brand. A user-friendly website and web applications play a crucial role for an Institute. If you are in search of a reliable website designing company, EdYug is the apt choice. At EdYug, we consider website designing as an art and that’s why we strive hard to turn your vision into ideas. To foster your ideas, we provide a fitting platform for its launch. We design a website in such a way that it can bring you the relevant web presence and that is why, we are known as one of the best website designing company.",
      coverImg: "https://edyug.com/img/websitedevelopment.jpg",
      slug: "website-development",
    },
    {
      id: 3,
      title: "Digital Marketing",
      post: 68,
      desc: "Digital marketing service of Edyug provides promotion and marketing of goods and services to consumers through digital channels and electronic technologies. These digital channels can include the internet, mobile devices, social media platforms, webinars, search engines, online customer communities and other digital platforms.",
      coverImg: "https://edyug.com/img/digital-marketing.jpg",
      slug: "digital-marketing",
    },
  ];

  // Find the blog using slug instead of id
  const blog = blogs.find((blog) => blog.slug === slug);

  // If no blog is found, return an error message or a "Not Found" component
  if (!blog) {
    return (
      <>
        <Navbar />
        <Breadcrumb title="Blogs">
          <p>
            Our cloud ERP software comes with customizable features along with
            web-portal and mobile app solutions.
          </p>
        </Breadcrumb>
        <div className="blogs-section">
          <div className="blogs-container">
            <h2 className="text-3xl font-bold text-center">Blog not found</h2>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Breadcrumb title="Blogs">
        <p>
          Our cloud ERP software comes with customizable features along with
          web-portal and mobile app solutions.
        </p>
      </Breadcrumb>
      <div className="blogs-section">
        <div className="blogs">
          <div className="blogs-container">
            <div className="blogs-content">
              <img
                className="w-[100%] shadow-lg"
                src={blog.coverImg}
                alt={blog.title}
              />
              <div className="blogs-content py-6 px-3">
                <h3 className="font-bold text-3xl text-gray-600 pb-4">
                  {blog.title}
                </h3>
                <p className="">{blog.desc}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-sidepanel">
          <div className="blog-category">
            <h3 className="text-3xl font-semibold mb-8 text-[#777777] border-l-4 pl-3">
              Service Category
            </h3>
            {blogs.map((blog) => (
              <Link
                key={blog.id}
                to={`/blogs/${blog.slug}`}
                className="blog-cat-1"
              >
                <img src={blog.coverImg} alt="postImg" />
                <h3 className="blog-cat-title font-semibold text-[16px]">
                  {blog.title}
                </h3>
                <span className="blog-cat-post">Click Now</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
