import React from "react";
import "../assets/Footer.css";
// import { blogs } from "../data/data";
import {
  FaEnvelope,
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaPhone,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
// import { CgWebsite } from "react-icons/cg";
// import { FaLocationDot } from "react-icons/fa6";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import BrandLogo from "../assets/images/edyug-logo.png";
import PrivacyPolicy from "../assets/pdfs/Privacy_Policy.pdf";

const Footer = () => {
  const blogs = [
    {
      id: 1,
      title: "School/College ERP Software",
      desc: "A blog is an informational website consisting of discrete, often informal diary-style text entries. Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page.",
      coverImg: "https://edyug.com/img/erps.jpg",
      slug: "school-college-erp-software", // Manually add slugs
    },
    {
      id: 2,
      title: "Website Development",
      desc: "A website where a person writes regularly about topics that interest them, usually with photographs and links to other websites they find interesting.",
      coverImg: "https://edyug.com/img/websitedevelopment.jpg",
      slug: "website-development",
    },
    {
      id: 3,
      title: "Digital Marketing",
      desc: "The earliest instance of a commercial blog was on the first business to consumer Web site created in 1995 by Ty, Inc., which featured a blog in a section called. The entries were maintained by featured Beanie Babies that were voted for monthly by Web site visitors.",
      coverImg: "https://edyug.com/img/digital-marketing.jpg",
      slug: "digital-marketing",
    },
  ];

  return (
    <>
      <section className="footer mt-[250px]">
        <div className="body-container footer-section">
          <div className="mr-10">
            <img
              style={{ width: "200px", marginBottom: "18px" }}
              src={BrandLogo}
              alt="edyugLogo"
            />
            <p>
              Edyug Provides exclusive education management software, which
              digitizes and automates activities of an institution. Our advanced
              education management system with a centralized database allows you
              to connect multiple departments.
            </p>
            <ul className="social-links">
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://instagram.com/"
                >
                  <FaInstagram className="social-icon text-[#17B6B6] hover:text-[#e31515]" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://facebook.com/"
                >
                  <FaFacebook className="social-icon text-[#17B6B6] hover:text-[#e31515]" />
                  {/* <FaFacebookF className="social-icon" /> */}
                  {/* <FaFacebookSquare className="social-icon" /> */}
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://wa.me/7391964582"
                >
                  <FaWhatsapp className="social-icon text-[#17B6B6] hover:text-[#e31515]" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://youtube.com/"
                >
                  <FaYoutube className="social-icon text-[#17B6B6] hover:text-[#e31515]" />
                </Link>
              </li>
              <li>
                <Link
                  style={{ cursor: "pointer" }}
                  target="_blank"
                  to="https://twitter.com/"
                >
                  <FaTwitter className="social-icon text-[#17B6B6] hover:text-[#e31515]" />
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3>Quick Links</h3>
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/schoolerp">School ERP</Link>
              </li>
              <li>
                <Link to="/collegeerp">College ERP</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <a href={PrivacyPolicy} target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Services</h3>
            <ul>
              {blogs.map((blog) => {
                return (
                  <>
                    <li key={blog}>
                      <Link to={`/blogs/${blog.slug}`}> {blog.title}</Link>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <div className="footer-location">
            <h3>Location</h3>
            <p>
              <FaMapMarkedAlt
                style={{ marginTop: "-90px" }}
                className="info-icon"
              />
              7th Floor, No 704Palm Court, <br /> M.G Road, near Dhola Chauk,
              <br /> Sector 16, Gurugram, <br /> Haryana India 122007
            </p>
            <p>
              <FaPhone className="info-icon" /> +91 7391964582
            </p>
            <p>
              <Link
                className="flex items-center gap-4"
                target="_blank"
                to="https://wa.me/7391964582"
              >
                <IoLogoWhatsapp className="info-icon" /> +91 7391964582
              </Link>
            </p>
            <p>
              <FaEnvelope className="info-icon" /> info@edyug.com
            </p>
            <p>
              <FaGlobe className="info-icon" />
              <Link
                className="text-[#37d1b7] hover:text-[#ee594d] font-semibold pl-1"
                to="https://edyug.com/"
                target="_blank"
              >
                edyug.com
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className="footer-bottom">
        <p className="text-center text-white">
          ©2024
          <Link
            className="text-[#37d1b7] hover:text-[#ee594d] font-semibold px-3"
            to="https://edyug.com/"
            target="_blank"
          >
            Edyug Technology Pvt. Ltd.
          </Link>
          All Rights Reserved
        </p>
      </section>
    </>
  );
};

export default Footer;
