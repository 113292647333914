import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
// import "../custom.js";
import "../assets/CounterSection.css";
import { FaUsers } from "react-icons/fa";
// import { FaUsersCog } from "react-icons/fa";
// import { IoRocket } from "react-icons/io5";
// import { BsTrophyFill } from "react-icons/bs";
import { FaSmileWink } from "react-icons/fa";
import { GiTrophy } from "react-icons/gi";
import { FaCalendarAlt } from "react-icons/fa";
// import { FaLayerGroup } from "react-icons/fa";

import CountUp from "../CountUp";

const CounterSection = () => {
  return (
    <div className="counters">
      <div className="counter-container">
        <motion.div
          className="box"
          variants={fadeIn("right", 0.1)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <div className="box-top">
            <div className="flex-counter">
              <div className="counter">
                <CountUp start={0} end={100} />
              </div>
              <span>+</span>
            </div>
            <FaSmileWink className="text-[50px]" style={{ color: "#17B6B6" }} />
          </div>
          <h3>Happy Clients</h3>
        </motion.div>

        <motion.div
          className="box"
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <div className="box-top">
            <div className="flex-counter">
              <div className="counter">
                <CountUp start={0} end={50000} />
              </div>
              <span>+</span>
            </div>
            <FaUsers className="text-[50px]" style={{ color: "#624DE3" }} />
          </div>
          <h3>Students Enrolled</h3>
        </motion.div>
        <motion.div
          className="box"
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <div className="box-top">
            <div className="flex-counter">
              <div className="counter">
                <CountUp start={0} end={365} />
              </div>
              <span>+</span>
            </div>
            <FaCalendarAlt
              className="text-[50px]"
              style={{ color: "#E34D4D" }}
            />
          </div>
          <h3>Days Of Work</h3>
        </motion.div>
        <motion.div
          className="box"
          variants={fadeIn("right", 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <div className="box-top">
            <div className="flex-counter">
              <div className="counter">
                <CountUp start={0} end={5} />
              </div>
              <span>+</span>
            </div>
            <GiTrophy className="text-[50px]" style={{ color: "#B81EE7" }} />
          </div>
          <h3>Award Winner</h3>
        </motion.div>
      </div>
    </div>
  );
};

export default CounterSection;
