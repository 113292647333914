import React from "react";
import { Link } from "react-router-dom";
import "../assets/ServicesSection.css";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const ServicesSection = () => {
  const blogs = [
    {
      id: 1,
      title: "School/College ERP Software",
      desc: "A blog is an informational website consisting of discrete, often informal diary-style text entries. Posts are typically displayed in reverse chronological order so that the most recent post appears first, at the top of the web page.",
      coverImg: "https://edyug.com/img/erps.jpg",
      slug: "school-college-erp-software", // Manually add slugs
    },
    {
      id: 2,
      title: "Website Development",
      desc: "A website where a person writes regularly about topics that interest them, usually with photographs and links to other websites they find interesting.",
      coverImg: "https://edyug.com/img/websitedevelopment.jpg",
      slug: "website-development",
    },
    {
      id: 3,
      title: "Digital Marketing",
      desc: "The earliest instance of a commercial blog was on the first business to consumer Web site created in 1995 by Ty, Inc., which featured a blog in a section called. The entries were maintained by featured Beanie Babies that were voted for monthly by Web site visitors.",
      coverImg: "https://edyug.com/img/digital-marketing.jpg",
      slug: "digital-marketing",
    },
  ];

  return (
    <>
      <section className="service-section">
        <div className="body-container">
          <div className="services-header md:pb-10">
            <motion.h3
              variants={fadeIn("left", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[18px] sm:text-2xl text-[#FF3951] font-bold text-center"
            >
              Our Services
            </motion.h3>

            <motion.h2
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[21px] sm:text-4xl md:text-5xl font-bold text-gray-600 text-center"
            >
              We Provide Exclusive Services
            </motion.h2>
          </div>
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="service-content"
          >
            {blogs.map((blog) => (
              <Link key={blog.id} to={`/blogs/${blog.slug}`}>
                <div className="service-card">
                  <img src={blog.coverImg} alt="serviceImg" />
                  <h3>{blog.title}</h3>
                </div>
              </Link>
            ))}
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
