import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import workImg from "../assets/images/work-process.svg";
import "../assets/WorkProcess.css";
import research from "../assets/images/icons/research.png";
import development from "../assets/images/icons/development.png";
import concept from "../assets/images/icons/concept.png";
import implementation from "../assets/images/icons/implementation.png";

const WorkProcess = () => {
  return (
    <>
      <div className="work-section">
        <div className="work-header pb-8 pt-4">
          <motion.h3
            variants={fadeIn("right", 0.1)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="text-[18px] sm:text-2xl text-[#FF3951] font-bold text-center"
          >
            Work Flow
          </motion.h3>

          <motion.h2
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="text-[21px] sm:text-4xl md:text-5xl  font-bold text-gray-600 text-center"
          >
            We Follow Our Work Process
          </motion.h2>
          <motion.p
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
          >
            Enthusiastically engage cross-media leadership skills for
            alternative experiences. Proactively drive vertical systems than
            intuitive architectures.
          </motion.p>
        </div>
        <div className="work-container">
          <div className="work-left">
            <motion.div
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="work-left-slide"
            >
              <div className="work-img-store">
                {/* <TbFolderSearch className="text-[80px] text-[#b81ee7]" /> */}
                <img src={research} className="work-img" alt="" />
              </div>
              <div>
                <p className="batch batch-1">Step 1</p>
                <h4 className="text-2xl font-semibold text-gray-700">
                  Research
                </h4>
                <p className="text-[13px] lg:text-[15px] text-gray-600 font-normal">
                  Process analysis with our standard software, Edyug also
                  provides custom based software for your institution. We
                  analyse your processes at this stage.
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="work-left-slide"
            >
              <div className="work-img-store">
                <img src={concept} className="work-img" alt="" />
              </div>
              <div>
                <p className="batch batch-2">Step 2</p>
                <h4 className="text-2xl font-semibold text-gray-700">
                  Concept
                </h4>
                <p className="text-[13px] lg:text-[15px] text-gray-600 font-normal">
                  Requirement gathering after Process analysing, our team start
                  gathering requirements according to your needs and creates a
                  project report.
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="work-left-slide"
            >
              <div className="work-img-store">
                <img src={development} className="work-img" alt="" />
              </div>
              <div>
                <p className="batch batch-3">Step 3</p>
                <h4 className="text-2xl font-semibold text-gray-700">
                  Development
                </h4>
                <p className="text-[13px] lg:text-[15px] text-gray-600 font-normal">
                  Once the requirements are gathered, we start building your
                  software.
                  <span
                    className="hide-mobile"
                    style={{ color: "transparent" }}
                  >
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Earum, modi?
                  </span>
                </p>
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("right", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="work-left-slide"
            >
              <div className="work-img-store">
                <img src={implementation} className="work-img" alt="" />
              </div>
              <div>
                <p className="batch batch-4"> Step 4</p>
                <h4 className="text-2xl font-semibold text-gray-700">
                  Implementation
                </h4>
                <p className="text-[13px] lg:text-[15px] text-gray-600 font-normal">
                  Once the development is complete and tested, we implement the
                  software and finally Institution start using it.
                  <span style={{ color: "transparent" }}>
                    Lorem ipsum dolor sit amet consectetur
                  </span>
                </p>
              </div>
            </motion.div>
          </div>
          <motion.div
            className="work-right"
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
          >
            <img src={workImg} className="work-img" alt="" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default WorkProcess;
