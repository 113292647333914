import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../assets/images/about-feature-img.svg";
import "../assets/AboutSection.css";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import BookDemo from "./BookDemo";

const AboutSection = () => {
  return (
    <>
      <div className="about-section">
        <div className="about-container">
          <div className="about-left">
            <motion.h3
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[18px] sm:text-2xl text-[#FF3951] font-bold pl-1"
            >
              About Us
            </motion.h3>
            <motion.h2
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[21px] sm:text-3xl md:text-[40px] font-bold text-gray-600"
            >
              Powerful Solutions for Your Educational Institution
            </motion.h2>
            <motion.span
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[16px] text-[#17b6b6] font-bold "
            >
              Education + Automation + Technology ={" "}
              <span className="text-[#FF3951]">Edyug</span>
            </motion.span>

            <motion.p
              variants={fadeIn("right", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              We are India’s leading company in providing{" "}
              <a
                className="font-bold text-[#17b6b6] hover:text-[#FF3951]"
                href="https://edyug.com/blogs/website-development"
              >
                software development
              </a>{" "}
              and management services particularly for the education industry.
              Our team and channel partners have great experience of fulfilling
              automation requirements of our clients from schools, colleges and
              universities.
            </motion.p>
            <motion.p
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              Education is the key behind every country’s success & development,
              Edyug has a vision of improving and developing our country’s
              education system for a better education standards.
            </motion.p>
            <motion.p
              variants={fadeIn("right", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              Edyug caters its clients across India for software development and
              maintenance services. Our 24*7 customer support system assure our
              customers about our vision.
            </motion.p>
            <motion.div
              variants={fadeIn("right", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex gap-4 my-2"
            >
              <Link to="/contact" className="about-btn">
                Contact Us
              </Link>
              {/* <Link to="/contact" className="book-btn">
                Book Demo
              </Link> */}
              <BookDemo />
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="about-right"
          >
            <img src={aboutImg} alt="" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
