import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Services from "./routes/Services";
import Blogs from "./routes/Blogs";
import ScrollToTop from "./components/ScrollToTop";
import SchoolErp from "./routes/SchoolErp";
import CollegeErp from "./routes/CollegeErp";
import "../src/index.css";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/schoolerp" element={<SchoolErp />} />
        <Route path="/collegeerp" element={<CollegeErp />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs/:slug" element={<Blogs />} />{" "}
        {/* Ensure this path uses `slug` */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
};

export default App;
