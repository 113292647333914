import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../routes/routes.css";
// import { useState } from "react";
// import Teams from "../components/Teams";
import AboutSection from "../components/AboutSection";
import Breadcrumb from "../components/Breadcrumb";
import WorkProcess from "../components/WorkProcess";
import AboutCards from "../components/AboutCards";

const About = () => {
  // const [tab, setTab] = useState(1);
  // const [toggle, setToggle] = useState(1);

  // function updateToggle(id) {
  //   setToggle(id);
  //   setTab(id);
  // }

  return (
    <div>
      <Navbar />
      <Breadcrumb title="About Us">
        <p>
          We are India’s leading company in providing software development and
          management services particularly for the education industry.
        </p>
      </Breadcrumb>
      <AboutSection />
      <AboutCards />
      <WorkProcess />

      <Footer />
    </div>
  );
};

export default About;
