import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
// import GallerySection from "../components/GallerySection";
import ServicesSection from "../components/ServicesSection";
// import Gallery from "./Gallery";
import Footer from "../components/Footer";
// import CategorySlider from "../components/CategorySlider";
// import Blog from "../components/Blog";
// import Teams from "../components/Teams";
// import TestimonialSection from "../components/TestimonialSection";
import AboutSection from "../components/AboutSection";
import WorkProcess from "../components/WorkProcess";

import CounterSection from "../components/CounterSection";
import WhyChoose from "../components/WhyChoose";
import FAQ from "../components/FAQ";
// import Products from "../components/Products";

const Home = ({ blogs }) => {
  return (
    <div>
      <Navbar />
      <Hero />
      <AboutSection />
      <ServicesSection />
      <WorkProcess />
      {/* <Teams /> */}
      <CounterSection />
      <WhyChoose />

      {/* <CategorySlider /> */}
      {/* <GallerySection /> */}
      {/* <Gallery /> */}
      {/* <Products /> */}
      <FAQ />
      {/* <TestimonialSection /> */}
      {/* <Blog blogs={blogs ? blogs : ""} /> */}
      <Footer />
    </div>
  );
};

export default Home;
