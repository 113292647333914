import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import "../assets/ModalForm.css";

const ModalForm = ({ isvisible, onClose }) => {
  const [result, setResult] = useState("");
  const [formData, setFormData] = useState({
    c_name: "",
    number: "",
    email: "",
    p_number: "",
    s_i_name: "",
    role: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
    // Reset the form
    setFormData({
      c_name: "",
      number: "",
      email: "",
      p_number: "",
      s_i_name: "",
      role: "",
    });

    // After submit form popup
    Swal.fire({
      title: "Thanks!",
      text: "The form was submitted successfully.",
      confirmButtonColor: "#17B6B6",
      confirmButtonText: "Go back",
    });
    // Close the modal after successful submission
    onClose();
  };

  // console.log(formData);
  console.log(result);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Modal Pop up

  if (!isvisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };

  return (
    <>
      <div
        id="wrapper"
        onClick={handleClose}
        className="overscroll-none fixed top-0 left-[-50px] sm:left-0 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center mx-auto"
        style={{ zIndex: 999999999999999 }}
      >
        <div className="book-demo-form bg-white w-[300px] mx-auto sm:w-[600px] p-4 sm:p-8 rounded-lg">
          <div className="book-title flex justify-between items-center sm:mb-4 mb-2">
            <h3 className="sm:text-3xl text-xl text-[#103A57] font-bold">
              Book A Free Demo
            </h3>
            <button
              onClick={() => onClose()}
              className="text-[#ee5b59] text-xl font-bold"
            >
              X
            </button>
          </div>
          <hr className="sm:mb-8 mb-4" />

          {/* Form Section */}

          <form
            action="https://portal.edyugsolutions.com/admin/Book_demo/insert_book_demo"
            className="space-y-2 sm:space-y-6"
            onSubmit={(event) => handleSubmit(event)}
            method="POST"
          >
            <div>
              <input
                type="text"
                name="c_name"
                value={formData.c_name}
                onChange={handleChange}
                autoComplete="off"
                id="text"
                className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
                placeholder="Contact Name *"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="off"
                id="email"
                className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
                placeholder="Email Address *"
                required
              />
            </div>
            <div>
              <input
                type="number"
                name="p_number"
                value={formData.p_number}
                onChange={handleChange}
                autoComplete="off"
                id="number"
                className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
                placeholder="Contact Number *"
                required
              />
            </div>
            <div>
              <input
                type="number"
                name="s_i_name"
                value={formData.s_i_name}
                onChange={handleChange}
                autoComplete="off"
                id="number"
                className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
                placeholder="School/Institute Number *"
                required
              />
            </div>

            <div>
              <select
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                autoComplete="off"
                required
                className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
              >
                <option value="" selected>
                  Select Role *
                </option>
                <option value="teacher-faculty">Teaching faculty</option>
                <option value="director">Director</option>
                <option value="dean">Dean</option>
                <option value="ceo">CEO</option>
                <option value="principal">Principal</option>
                <option value="founder">Founder</option>
                <option value="administrator">Administrator</option>
                <option value="owner">Owner</option>
                <option value="president">President</option>
                <option value="student">Student</option>
                <option value="other">Other</option>
              </select>
            </div>
            <button
              type="submit"
              className="w-full h-10 rounded-md text-[19px] font-[600] text-white bg-[#ee5b59] hover:bg-[#17b6b6] focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalForm;
