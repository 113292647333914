import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import "./Contact.css";
import Breadcrumb from "../components/Breadcrumb";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaMapMarkedAlt } from "react-icons/fa";
import { TiContacts } from "react-icons/ti";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import Swal from "sweetalert2";
import $ from "jquery";
import { useState } from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  const [result, setResult] = useState("");

  const [formData, setFormData] = useState({
    username: "",
    number: "",
    email: "",
    institute: "",
    desc: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
    // Reset the form
    setFormData({
      username: "",
      number: "",
      email: "",
      institute: "",
      desc: "",
      message: "",
    });

    // After submit form popup
    Swal.fire({
      title: "Thanks!",
      text: "The form was submitted successfully. Our Team will connect with you for a Demo.",
      confirmButtonColor: "#17B6B6",
      confirmButtonText: "Go back",
    });
  };
  // console.log(formData);
  // console.log(result);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Navbar />
      <Breadcrumb title="Contact">
        <p>We would love to hear from you.</p>
      </Breadcrumb>
      <div className="contact-page">
        <div className="container">
          <motion.h1
            variants={fadeIn("right", 0)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="text-center text-4xl sm:text-6xl lg:text-7xl text-gray-600 font-bold mt-12 md:pb-8"
          >
            Get In Touch
          </motion.h1>

          {/* Contact Information */}
          <div className="contact-information">
            <motion.div
              className="contact-card"
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <FaEnvelopeOpenText className="contact-icon text-[#17B6B6]" />
              <h4>Email</h4>
              <p>info@edyug.com</p>
            </motion.div>
            <motion.div
              className="contact-card"
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <TiContacts className="contact-icon text-[#FFC226]" />
              <h4>Phone</h4>
              <p className="flex items-center gap-2">
                <Link
                  className="flex items-center gap-2"
                  target="_blank"
                  to="https://wa.me/7391964582"
                >
                  <IoLogoWhatsapp className="text-[#17B6B6]" /> +91 7391964582
                </Link>
              </p>
              <p className="flex items-center gap-2">
                <FaPhone className="text-[#E34D4D]" /> +91 7391964582
              </p>
            </motion.div>
            <motion.div
              className="contact-card"
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <FaMapMarkedAlt className="contact-icon text-[#E34D4D]" />
              <h4>Address</h4>
              <p>
                7th Floor, No 704Palm Court, M.G Road, near Dhola Chauk, Sector
                16, Gurugram, Haryana India 122007
              </p>
            </motion.div>
          </div>

          <div className="contact-section-grid">
            {/* Forms */}
            <motion.div
              className="form-container"
              variants={fadeIn("right", 0.1)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <form
                action="https://portal.edyugsolutions.com/admin/Contact_web/insert_contactWeb"
                onSubmit={(event) => handleSubmit(event)}
                method="POST"
              >
                <label> Name *</label>
                <input
                  value={formData.username}
                  onChange={handleChange}
                  type="text"
                  name="username"
                  placeholder="Name"
                  autoComplete="off"
                  required
                />

                <label>Phone Number *</label>
                <input
                  value={formData.number}
                  onChange={handleChange}
                  type="number"
                  maxLength={10}
                  minLength={10}
                  name="number"
                  placeholder="Phone Number"
                  autoComplete="off"
                  required
                />

                <label>Email *</label>
                <input
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />

                <label>School/College/Institute Name *</label>
                <input
                  value={formData.institute}
                  onChange={handleChange}
                  type="text"
                  name="institute"
                  placeholder="School/College/Institute Name"
                  autoComplete="off"
                  required
                />

                <select
                  id="selectvalue"
                  name="desc"
                  value={formData.desc}
                  onChange={handleChange}
                  autoComplete="off"
                  required
                  className="bg-gray-50 border-gray-300 border-[1px] outline-none text-gray-900 text-sm rounded-md w-full p-2.5"
                >
                  <option value="none" selected>
                    Select Role *
                  </option>
                  <option value="teacher-faculty">Teaching faculty</option>
                  <option value="director">Director</option>
                  <option value="dean">Dean</option>
                  <option value="ceo">CEO</option>
                  <option value="principal">Principal</option>
                  <option value="founder">Founder</option>
                  <option value="administrator">Administrator</option>
                  <option value="owner">Owner</option>
                  <option value="president">President</option>
                  <option value="student">Student</option>
                  <option value="other">Other</option>
                </select>

                <label>Messege</label>
                <textarea
                  value={formData.message}
                  onChange={handleChange}
                  name="message"
                  placeholder="Write your message.."
                  autoComplete="off"
                  style={{ height: "80px" }}
                ></textarea>

                {/* <input onClick={handleClick} type="submit" value="Submit" /> */}
                <button className="formBtn" type="submit">
                  Submit
                </button>
              </form>
              {/* <h1>{result}</h1> */}
            </motion.div>
            {/* Map  Section*/}
            <motion.div
              variants={fadeIn("right", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <iFrame
                style={{ width: "100%", height: "670px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14024.83497741665!2d77.069027!3d28.5033667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19e9ce034bef%3A0xebe02835464090b9!2sEdyug%20Technology%20Private%20Limited!5e0!3m2!1sen!2sin!4v1704614644108!5m2!1sen!2sin"
              ></iFrame>
            </motion.div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
