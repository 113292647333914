import React from "react";
import "../assets/Breadcrumb.css";

const Breadcrumb = ({ title, image, children }) => {
  return (
    <>
      <div className="breadcrumb">
        <div className="breadcrumb-container">
          {/* <div className="breadcrumb-bg">
            <img src={image} alt="breadcrumb background" />
          </div> */}

          <h1 className="text-4xl md:text-5xl lg:text-7xl text-[#37d1b7] font-bold  md:font-extrabold tracking-wide">
            {title}
          </h1>
          <p className="text-gray-400 text-xl sm:text-2xl md:text-3xl">
            {children}
          </p>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
