import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import Accordion from "./Accordion";
import faqImg from "../assets/images/why-choose.svg";
import "../assets/faq.css";

const FAQ = () => {
  return (
    <>
      <motion.div
        className="faq-section"
        variants={fadeIn("right", 0.1)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
      >
        <div className="team-header sm:pb-14 pb-10 sm:pt-10">
          <h2 className="text-3xl sm:text-4xl md:text-5xl  font-bold text-gray-600 text-center sm:mb-8">
            {/* Know More About Our It Solution */}
            F.A.Q
          </h2>
        </div>
        <div className="faq-container">
          <div className="faq-left">
            <div className="accordion">
              <Accordion
                className="accord-slide"
                title="What is SIS?"
                answer="A Student Information System (SIS) is a software application used by campus to manage student data. It is a centralized database that contains information about students, such as their personal information, academic performance, attendance records, and demographic data. The system is used by school administrators, teachers, and staff to track and manage student data throughout their academic journey, from enrollment to graduation"
              />
              <Accordion
                className="accord-slide"
                title="How ERP Works for School, Colleges and Universities ?"
                answer="Enterprise Resource Planning (ERP) systems for schools, colleges, and campus work by integrating and automating various administrative processes such as student information management, admission, attendance, fee collection, and academic records."
              />
              <Accordion
                className="accord-slide"
                title="How to Implement Edyug’s ERP ?"
                answer="Implementing an ERP system requires careful planning, stakeholder engagement, and a phased approach. The process typically involves defining requirements, selecting a vendor, configuring the software, testing, training, and data migration. EdYug’s ERP software has an easy installation process. Our team will be part of the implementation process from starting to end,  offering continuous ongoing support."
              />
              {/* sample test */}
              <Accordion
                className="accord-slide"
                title="Can we manage school records through school management system ?"
                answer="Yes, a school management system (SMS) can maintain all school records, including student and staff data, attendance, grades, schedule, fees, and other administrative tasks. An SMS streamlines and automates these processes, enabling schools to manage their operations more efficiently and accurately while providing real-time access to data for decision-making and analysis."
              />
              <Accordion
                className="accord-slide"
                title="Can we manage fee payment through the school management system ?"
                answer="Yes, Edyug provides a comprehensive fee management module that enables the campus to manage fee payment and records through the school management system. The software allows administrators to create fee structures, collect payment online or offline, generate invoices, and track payment history. "
              />
              <Accordion
                className="accord-slide"
                title="How College ERP helps HEIs to prepare for accreditation ?"
                answer="College ERP systems like Edyug can help higher education institutions (HEIs) prepare for accreditation by automating the quality assurance function and generating one-click reports from the Quality Assessment and Accreditations Module for NBA, NIRF, and NAAC, including the annual AQAR. EDYUG  Performance Insight offers a unified view of quality-related key result areas (KRAs) across all parameters and functions, providing HEIs with valuable insights to improve their accreditation outcome."
              />
              {/* sample test */}
              <Accordion
                className="accord-slide"
                title="How does ERP help in managing students' admission process ?"
                answer="ERP systems like EDYUG offer comprehensive features to manage student admission systems. EdYug's digital admission system enables institutions to streamline their admission processes, from online applications to document verification, fee payments, and enrollment. EdYug's centralized platform also helps institutions manage student attendance, assessments and other records, making it easy to access and analyze critical information in real time."
              />
            </div>
          </div>
          <div className="faq-right">
            <img src={faqImg} alt="" />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default FAQ;
