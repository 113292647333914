import React from "react";
import "../assets/Modules.css";
import { modulesData } from "../data/data";

const Modules = () => {
  return (
    <>
      <div className="module-section">
        <div className="module-header pb-8 lg:pb-10">
          <h3 className="text-[18px] sm:text-2xl text-[#FF3951] font-bold text-center">
            Modules
          </h3>

          <h2 className="text-[21px] sm:text-3xl md:text-5xl  font-bold text-gray-600 text-center">
            Edyug has 20+ Modules for your Institution
          </h2>
        </div>
        <div className="module-container">
          <div className="module-storage">
            {modulesData.map((mod, index) => {
              return (
                <>
                  <div className="module-slide" key={index}>
                    <div style={{ width: "120px" }}>
                      <img src={mod.image} className="module-img" alt="" />
                    </div>
                    <div>
                      <p className="batch batch-1">Module {mod.id}</p>
                      <h6 className="text-[16px] font-semibold text-gray-700">
                        {mod.title}
                      </h6>
                      <p className="text-[13px] lg:text-[15px] text-gray-600 font-normal">
                        {mod.desc}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modules;
