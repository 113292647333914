import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
// import { FaEnvelopeOpenText } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa";
import { FaCompressArrowsAlt } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import "../assets/AboutCards.css";

const AboutCards = () => {
  return (
    <div className="about-information">
      <motion.div
        className="about-card"
        variants={fadeIn("right", 0.1)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
      >
        <FaCompressArrowsAlt className="about-icon text-[#17B6B6]" />

        <p>
          Edyug provides exclusive education management software, which
          digitizes and automates activities of an institution. Our advanced
          education management system with a centralized database allows you to
          connect multiple departments through one single integrated platform.
        </p>
      </motion.div>
      <motion.div
        className="about-card"
        variants={fadeIn("right", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
      >
        <FaLayerGroup className="about-icon text-[#FFC226]" />
        <p>
          Edyug helps institutions to have an overall view of all the operations
          carried out in various departments with the help to reporting panel
          for each activity managed through our platform. We provide web &
          mobile based applications for users with different roles – admin,
          super admin, mentor, parent, accountant and many more.
        </p>
      </motion.div>
      <motion.div
        className="about-card"
        variants={fadeIn("right", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
      >
        <FaUsersCog className="about-icon text-[#E34D4D]" />
        <p>
          Our team has years of experience in delivering customized system
          according to the clients requirements.
        </p>
      </motion.div>
    </div>
  );
};

export default AboutCards;
