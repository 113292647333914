import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ModalForm from "./ModalForm";
import "../assets/BookDemo.css";

const BookDemo = () => {
  const [showModal, setShowModal] = useState();

  //   useEffect(() => {
  //     document.body.style.overflow = showModal ? "hidden" : "unset";
  //   }, [showModal]);
  let ref = useRef(0);
  useEffect(() => {
    ref.current = ref.current + 1;
    if (showModal) {
      document.body.style.overflow = "hidden";
      //   document.body.style.scroll = "none";
      //   document.body.style.overflowY = "hidden";
      //   document.body.style.overflowX = "hidden";
      document.body.style.height = "100vh";

      //   window.scroll = "none";
      //   window.overflowY = "hidden";
      document.body.style.paddingRight = "0";
    }
    return () => {
      document.body.style.overflow = "scroll";
      document.body.style.paddingRight = "0px";
    };
  }, [showModal]);

  return (
    <>
      <Link onClick={() => setShowModal(true)} className="book-demo-btn">
        Book Demo
      </Link>
      <ModalForm isvisible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default BookDemo;
